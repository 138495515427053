import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from './auth'

export default function ForgotPassword() {
  const { setSession } = useContext(AuthContext)
  const navigate = useNavigate()
  const [showVerify, setShowVerify] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [verification, setVerification] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleForgotPassword = async (event: any) => {
    event.preventDefault()
    try {
      await resetPassword({ username: email })
      setShowVerify(true)
    } catch (error) {
      console.log('forgotPassword', error)
    }
  }

  const handleVerification = async (event: any) => {
    event.preventDefault()
    try {
      await confirmResetPassword({ username: email, newPassword: password, confirmationCode: verification })
      setSession()
      navigate('/sign-in')
    } catch (error) {
      console.log('confirmSignUp error', error)
    }
  }

  return showVerify ? (
    <>
      <h1>Verify</h1>
      <p>An email has been sent to {email} containing a code to verify your email address.</p>
      <Form onSubmit={handleVerification}>
        <Form.Group className="mb-3" controlId="verification">
          <Form.Label>Verification code</Form.Label>
          <Form.Control type="text" value={verification} onChange={(e) => setVerification(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>
        <Button type="submit">Reset password</Button>
      </Form>
    </>
  ) : (
    <>
      <h1 className="mb-4">Reset password</h1>
      <p>
        Already have an account? <a href="/sign-in">Sign in here</a>
      </p>
      <Form onSubmit={handleForgotPassword}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Button type="submit">Reset password</Button>
      </Form>
    </>
  )
}
