import { useState, useEffect } from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'

const Reports = () => {
  const [allReports, setAllReports] = useState<any[]>([])
  const [filteredReports, setFilteredReports] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    const getReports = async () => {
      try {
        const url = `${process.env.REACT_APP_PRIVATE_API_URL || ''}/list-reports`
        const { data } = await axios.get(url)
        const mappedData = data.map((report: any) => ({
          ...report,
          branch: getBranch(report.Key),
        }))
        setAllReports(mappedData)
        setFilteredReports(mappedData)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    void getReports()
  }, [])

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    const reports = allReports.filter((report) => report.Key.includes(value))
    setFilteredReports(reports)
  }

  if (!loading) {
    return (
      <>
        <h1 className="mb-4">Reports</h1>
        <Form>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>Search by branch</Form.Label>
            <Form.Control type="text" value={searchTerm} onChange={(e) => handleSearch(e.target.value)} />
          </Form.Group>
        </Form>
        <ul>
          {filteredReports.map((report) => (
            <li>
              <a href={`${process.env.REACT_APP_PRIVATE_API_URL}/get-report/${report.branch}`}>{report.branch}</a>
            </li>
          ))}
        </ul>
      </>
    )
  }
}

function getBranch(key: string) {
  return key.split('/')[1]
}

export default Reports
