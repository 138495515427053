import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from './auth'

export default function PrivateRoute({ children }) {
  const { jwt } = useContext(AuthContext)

  return jwt ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/',
      }}
    />
  )
}
