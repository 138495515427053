import { useQuery, gql } from '@apollo/client'
import Table from 'react-bootstrap/Table'

const ORDERS = gql`
  query {
    orders {
      created
      id
      lineItems {
        product {
          code
          name {
            long
          }
        }
      }
    }
  }
`

const Orders = () => {
  const { data, loading } = useQuery(ORDERS)

  if (!loading) {
    return (
      <>
        <h1 className="mb-4">Orders</h1>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Created</th>
              <th>Product name(s)</th>
              <th>Product code(s)</th>
            </tr>
          </thead>
          <tbody>
            {data.orders.map((order: any) => (
              <tr key={order.code}>
                <td>{order.id}</td>
                <td>{order.created}</td>
                <td>{order.lineItems.map(({ product }: any) => product.name.long).join(', ')}</td>
                <td>{order.lineItems.map(({ product }: any) => product.code).join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )
  }
}

export default Orders
