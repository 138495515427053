import { useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { AuthContext } from './auth'

const ACCOUNT = gql`
  query {
    account {
      id
      firstName
      lastName
      email
      phone
      dob
      marketingOptIn
      status
    }
  }
`

const Account = () => {
  const { data, loading } = useQuery(ACCOUNT)
  const { jwt } = useContext(AuthContext)

  const copyGraphQlHeader = () => {
    void navigator.clipboard.writeText(
      JSON.stringify(
        {
          authorization: `Bearer ${jwt}`,
        },
        undefined,
        '  '
      )
    )
  }

  if (!loading) {
    return (
      <>
        <h1 className="mb-4">Customer</h1>

        <h2 className="mb-2">Data</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              {Object.keys(data.account).map((key: any) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.values(data.account).map((value: any, index: number) => (
                <td key={`account${index}`}>{value.toString()}</td>
              ))}
            </tr>
          </tbody>
        </Table>
        <h2 className="mb-2">Access token</h2>
        <p>
          <code>{jwt?.toString()}</code>
        </p>
        <Button onClick={copyGraphQlHeader}>Copy GraphQL header</Button>
      </>
    )
  }
}

export default Account
