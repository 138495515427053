import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import { Amplify, ResourcesConfig } from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css'

import AuthProvider from './auth'

const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID as string,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID as string,
    },
  },
}

Amplify.configure(config)

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
)
